.badge-active, .badge-done, .badge-publish {
    background-color: var(--#{$prefix}badge-active-bg);
    color: var(--#{$prefix}badge-active-color);
}

.badge-approved , .badge-approve{
    background-color: #BCFBCE;
    color: #61AA76;
}

.badge-inprogress {
    background-color: #fbedbc;
    color: black;
}

.badge-pending{
    background-color: #FBEDBC;
    color: #7A692A;
}

.badge-completed{
    background-color: #e8fff3;
    color: #50cd89;
}

.badge-deactive, .badge-inactive, .badge-aborted {
    background-color: var(--#{$prefix}badge-deactive-bg);
    color: var(--#{$prefix}badge-deactive-color);
}

 .badge-archive, .badge-reassign{
    background-color: var(--#{$prefix}light-purple);
    color: var(--#{$prefix}black-color);
}

.badge-draft {
    background-color: #ecf1ff;
    color: var(--#{$prefix}black-color);
}

.badge-inprocess {
    background-color: #e4f3c4;
    color: var(--#{$prefix}black-color);
}

.badge-seo {
    background-color: #84acf1;
    color: var(--#{$prefix}black-color);
}

.badge-reject{
    background-color: #FBBCBC;
    color: #9A4E4E;
}

.badge-review {
    background-color: #efc5a8;
    color:black;
}

.badge.review{
    background-color: #efc5a8;
    color:black;
}

.badge-schedule {
    background-color: #c5a9a9;
    color:black;
}

.badge-publish {
    background-color: #a3f5d9;
    color:black;
}

.badge-assign_to {
    background-color: var(--#{$prefix}btn-primary);
    color: var(--#{$prefix}white-color);
}

.badge-light-purple {
    background-color: #aeb6e4;
    color:black;
}

.badge-live {
    background-color: #c5f4c9;
    color:black;
}
