//
// Quill
//

$custom-border-color: #b8c1d8;

// Base
.ql-toolbar {
    font-family: $font-family-sans-serif;

    &.ql-snow {
        // border: 1px solid var(--#{$prefix}border-color);
        border: 2px solid $custom-border-color;
        @include border-top-radius($border-radius);

        .ql-picker,
        button {

            .ql-fill,
            .ql-stroke {
                stroke: currentColor;
            }

            .ql-fill {
                fill: currentColor;
            }

            &.ql-expanded,
            &.ql-active,
            &:focus,
            &:hover {

                .ql-fill,
                .ql-stroke {
                    // stroke: var(--#{$prefix}primary);
                    stroke: currentColor;
                }

                .ql-fill {
                    // fill: var(--#{$prefix}primary);
                    fill: currentColor;
                }
            }
        }
    }
}

.ql-editor {
    color: var(--#{$prefix}input-color);
    min-height: 40px !important;
    word-break: break-word;

    &.ql-blank {
        min-height: 40px !important;

        &:before {
            display: none;
            left: auto !important;
            right: auto !important;
            color: var(--#{$prefix}text-muted) !important;
            font-style: normal !important;
        }
    }
}

.design-preview-container,
.html-preview-container {
    .ql-editor {
        min-height: fit-content !important;
    }
}

.ql-container {
    &.ql-snow {
        background-color: var(--#{$prefix}input-bg);
        // border: 1px solid var(--#{$prefix}gray-200);
        border: 2px solid $custom-border-color;
        border-top: 0;
        @include border-bottom-radius($border-radius);
    }
}

.ql-snow {
    .ql-picker {
        .ql-picker-label {
            color: var(--#{$prefix}gray-500);

            &.ql-active,
            &:hover {
                color: var(--#{$prefix}primary);
            }
        }

        &.ql-expanded {
            outline: none !important;
            border-color: ar(--#{$prefix}bg-transparent) !important;

            .ql-picker-label {
                border-color:var(--#{$prefix}bg-transparent) !important;
                color: var(--#{$prefix}primary);
                outline: none !important;

                &.ql-active,
                &:hover {
                    color: var(--#{$prefix}primary);
                }
            }

            .ql-picker-options {
                border: 0;
                // padding: 0.5rem 1rem;
                padding: 0.5rem 0.7rem;
                // box-shadow: var(--#{$prefix}dropdown-box-shadow);
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                background-color: var(--#{$prefix}body-bg);
                @include border-radius($border-radius);

                .ql-picker-item {
                    color: var(--#{$prefix}gray-600);
                    outline: none;
                    margin-bottom: 3px;
                    padding-left: 6px;
                    padding-right: 6px;
                    transition: background-color 0.2s ease-in-out;

                    &.ql-selected,
                    &.ql-active,
                    &:hover {
                        border-radius: 4px;
                        background-color: #e2eaff;
                        color: var(--#{$prefix}primary);
                    }
                }
            }

            &.custom-ql-align {
                .ql-picker-options {
                    display: flex;
                    gap: 3px;

                    .ql-picker-item {
                        padding-left: 4px;
                        padding-right: 4px;
                        margin-bottom: 0px;
                    }
                }


            }
        }
    }

    .ql-tooltip {
        border: 0;
        padding: 0.5rem 1rem;
        box-shadow: var(--#{$prefix}dropdown-box-shadow);
        z-index: 99;
        top: 25px !important;
        left: 5px !important;
        @include border-radius($border-radius);

        input[type=text] {
            @include input-reset();
            border: 1px solid var(--#{$prefix}border-color);
            color: $input-plaintext-color;
            outline: none !important;
            @include border-radius($border-radius);

            &:active,
            &:focus {
                border-color: var(--#{$prefix}input-focus-border-color) !important;
            }
        }

        .ql-preview {
            color: var(--#{$prefix}gray-600);
        }

        .ql-action {
            transition: color 0.3s ease;
            color: var(--#{$prefix}gray-600);

            &:hover {
                transition: color 0.3s ease;
                color: var(--#{$prefix}primary);
            }
        }

        // Tooltip adjustment in modal
        .modal & {
            &.ql-editing {
                left: 20px !important;
            }
        }
    }

    .ql-editor {
        pre.ql-syntax {
            background-color: var(--#{$prefix}gray-900);
            color: var(--#{$prefix}text-muted);
            overflow: visible;
            @include border-radius($border-radius);
        }
    }
}

// Plain Style
.ql-quil.ql-quil-plain {
    .ql-toolbar {
        padding: 0;
        margin: 0;
        border: 0;

        &:after {
            display: none;
        }

        .ql-picker-label {
            padding-left: 0;
        }
    }

    .ql-container {
        border: 0;
    }

    .ql-editor {
        border: 0;
        padding: 0;
    }
}

// custom styles

 .ql-editor{
     p{
         &:not(:last-child){
             margin-bottom: 0.5rem;
         }
     }
 }

 .ql-editor {
    ol , ul{
        padding-left: 1.25rem;
        li{
            position: relative;
            margin-bottom: 0.5rem;
        }
    }
}
.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 0;
}

.preview-bullet-point li.ql-indent-1:not(.ql-direction-rtl), .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 0;
    margin-left: 24px !important;
}
.ql-editor{
    p{
        &:not(:last-child){
            margin-bottom: 0.5rem;
        }
    }
}

.ql-editor {
    min-height: 50px !important;

    &>*:not(img, .image-compare) {
        cursor: text;
    }

    ol {
        list-style: auto;
        // list-style-position: inside;
    }
}