.p-datatable {
  position: relative;
}

.p-datatable table {
  border-collapse: collapse;
  min-width: 100%;
  table-layout: fixed;
}

.p-datatable .p-sortable-column {
  cursor: pointer;
  user-select: none;
}

.p-datatable .p-sortable-column .p-column-title,
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column .p-sortable-column-badge {
  vertical-align: middle;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.p-datatable-auto-layout > .p-datatable-wrapper {
  overflow-x: auto;
}

.p-datatable-auto-layout > .p-datatable-wrapper > table {
  table-layout: auto;
}

.p-datatable-responsive-scroll > .p-datatable-wrapper {
  overflow-x: auto;
}

.p-datatable-responsive-scroll > .p-datatable-wrapper > table,
.p-datatable-auto-layout > .p-datatable-wrapper > table {
  table-layout: auto;
}

// .p-datatable-hoverable-rows .p-selectable-row {
//   cursor: pointer;
// }

/* Scrollable */
.p-datatable-scrollable .p-datatable-wrapper {
  position: relative;
  overflow: auto;
}

.p-datatable-scrollable .p-datatable-thead,
.p-datatable-scrollable .p-datatable-tbody,
.p-datatable-scrollable .p-datatable-tfoot {
  display: block;
}

.p-datatable-scrollable .p-datatable-thead > tr,
.p-datatable-scrollable .p-datatable-tbody > tr,
.p-datatable-scrollable .p-datatable-tfoot > tr {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.p-datatable-scrollable .p-datatable-thead > tr > th,
.p-datatable-scrollable .p-datatable-tbody > tr > td,
.p-datatable-scrollable .p-datatable-tfoot > tr > td {
  display: flex;
  flex: 1 1 0;
  align-items: center;
}

.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-virtual-scrollable-body > .cdk-virtual-scroll-content-wrapper > .p-datatable-table > .p-datatable-thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-frozen-tbody {
  position: sticky;
  z-index: 1;
}

.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.p-datatable-scrollable .p-frozen-column {
  position: sticky;
  background: inherit;
}

.p-datatable-scrollable th.p-frozen-column {
  z-index: 1;
}

.p-datatable-scrollable-both .p-datatable-thead > tr > th,
.p-datatable-scrollable-both .p-datatable-tbody > tr > td,
.p-datatable-scrollable-both .p-datatable-tfoot > tr > td,
.p-datatable-scrollable-horizontal .p-datatable-thead > tr > th
.p-datatable-scrollable-horizontal .p-datatable-tbody > tr > td,
.p-datatable-scrollable-horizontal .p-datatable-tfoot > tr > td {
  flex: 0 0 auto;
}

.p-datatable-flex-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.p-datatable-flex-scrollable .p-datatable-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.p-datatable-scrollable .p-rowgroup-header {
  position: sticky;
  z-index: 1;
}

.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead,
.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot {
  display: table;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead > tr,
.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot > tr {
  display: table-row;
}

.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead > tr > th,
.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot > tr > td {
  display: table-cell;
}

/* Flex Scrollable */
.p-datatable-flex-scrollable {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.p-datatable-flex-scrollable .p-datatable-virtual-scrollable-body {
  flex: 1;
}

/* Resizable */
.p-datatable-resizable > .p-datatable-wrapper {
  overflow-x: auto;
}

.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable-resizable .p-datatable-tbody > tr > td {
  overflow: hidden;
  white-space: nowrap;
}

.p-datatable-resizable .p-resizable-column:not(.p-frozen-column) {
  background-clip: padding-box;
  position: relative;
}

.p-datatable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
  display: none;
}

.p-datatable .p-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: .5rem;
  height: 100%;
  padding: 0px;
  cursor:col-resize;
  border: 1px solid transparent;
}

.p-datatable .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
}

.p-datatable .p-row-editor-init,
.p-datatable .p-row-editor-save,
.p-datatable .p-row-editor-cancel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

/* Expand */
.p-datatable .p-row-toggler {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

/* Reorder */
.p-datatable-reorder-indicator-up,
.p-datatable-reorder-indicator-down {
  position: absolute;
  display: none;
}

.p-datatable-reorderablerow-handle {
  cursor: move;
}

[pReorderableColumn] {
  cursor: move;
}

.p-reorderable-column-disabled {
  cursor: default !important; 
}

/* Loader */
.p-datatable .p-datatable-loading-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

/* Filter */
.p-column-filter-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.p-column-filter-menu {
  display: inline-flex;
}

.p-column-filter-row p-columnfilterformelement {
  flex: 1 1 auto;
  width: 1%;
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}

.p-column-filter-overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.p-column-filter-row-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-column-filter-row-item {
  cursor: pointer;
}

.p-column-filter-add-button,
.p-column-filter-remove-button {
  justify-content: center;
}

.p-column-filter-add-button .p-button-label,
.p-column-filter-remove-button .p-button-label {
  flex-grow: 0;
}

.p-column-filter-buttonbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-column-filter-buttonbar .p-button {
  width: auto;
}

/* Responsive */
.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
  display: none;
}

/* Virtual Scroll*/

cdk-virtual-scroll-viewport {
  outline: 0 none;
}
