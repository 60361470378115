.translate-n100 {
    transform: translateX(100%);
}

.drawer-transition {
    transition: transform 400ms ease-in-out;
}

.editor-drawer-shadow {
    box-shadow: 0px 12px 18px #DADEE8;
}

.editor-drawer-header-nmargin {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-top: -0.75rem;
}