.w-270px {
  width: 270px;
}

.h-100vh {
  height: 100vh;
}

.max-h-300px {
  max-height: 300px;
}

.max-h-225px {
  max-height: 225px;
}

.max-h-125px {
  max-height: 125px;
}

.max-h-150px {
  max-height: 150px;
}
.max-h-600px {
  max-height: 600px;
}
.w-fit-content {
  width: fit-content;
}
.rounded-top-3 {
  border-top-right-radius: 0.625rem;
}

.table-skeleton-loader {
  width: calc(100% - 40px) !important;
}

.rounded-3px {
  border-radius: 3px;
}

.h-32px {
  height: 32px;
}

.w-0 {
  width: 0;
}

.max-w-150px {
  max-width: 150px;
}

.max-w-100px {
  max-width: 100px;
}

.min-w-500px {
  min-width: 500px;
}

.min-w-120px {
  min-width: 120px;
}

.h-fit {
  height: fit-content;
}

.min-h-350px {
  min-height: 350px;
}

.min-h-150px {
  min-height: 150px;
}


.w-150px {
  width: 150px !important;
}

.min-h-43px {
  min-height: 43px;
}

.max-h-500px {
  max-height: 500px;
}

.w-72 {
  width: 72%;
}

.w-185px {
  width: 185px;
}

.max-h-250px {
  max-height: 250px;
}

.max-h-200px {
  max-height: 200px;
}

.w-max-100 {
  max-width: 100%;
}

.min-height-100 {
  min-height: 100%;
}

.h-300px {
  height: 300pc;
}

.w-320px {
  width: 335px;
}

.max-w-200px {
  max-width: 200px;
}

.max-w-300px {
  max-width: 300px;
}

.max-w-450px{
  max-width: 450px;
}
.right-10px {
  right: 10px;
}

.min-w-130px {
  min-width: 130px;
}
