.empty-none:empty {
    display: none;
}

.btn-reset {
    background-color: transparent;
    border: 0;
    outline: none;
}

.inner-anchor-underline {
    a:hover {
        text-decoration: underline !important;
    }
}

.rotate-n90 {
    transform: rotate(-90deg);
}

.transition-rotate {
    transition: 0.3s transform ease-in-out;
}