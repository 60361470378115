/* Utility Classes Start */

/* Displays Start */

.jg-d-block {
  display: block;
}

/* Displays End */

/* -- Flex Start -- */
.jg-d-flex {
  display: flex;
}

.jg-mb-8px{
  margin-bottom: 8px;
}

.jg-d-inline-flex {
  display: inline-flex;
}

.jg-text-nowrap{
  text-wrap:nowrap;
}

.jg-jc-center {
  justify-content: center;
}

.jg-jc-between {
  justify-content: space-between;
}

.jg-ai-center {
  align-items: center;
}

.jg-flex-wrap {
  flex-wrap: wrap !important;
}

.jg-flex-fill {
  flex: 1 1 auto;
}

.jg-gap-3 {
  gap: 15px;
}

/* -- Flex End -- */

/* -- Gap Start -- */
.jg-gap-10px {
  gap: 10px;
}

.jg-gap-5px {
  gap: 5px;
}

/* -- Gap End -- */

/* Width Start */

.jg-w-100 {
  width: 100%;
}

.jg-overflow-x-auto {
  overflow-x: auto;
}

.jg-h-220px {
  height: 200px;
}

.jg-inner-h-svg-100 svg {
  height: 100%;
}


.jg-inner-max-w-100 svg {
  max-width: 100%;
}

.jg-inner-w-100 svg {
  width: 100%;
}

.jg-w-50 {
  width: 50%;
}

.jg-max-w-50 {
  max-width: 50%;
}

.jg-max-w-100 {
  max-width: 100%;
}

/* Width End */

.jg-h-100 {
  height: 100%;
}

/* Width Start */

/* Width End */

/* Utility Classes End */




/* Spacing Classes Start */

/* Spacing Classes End */





/* Control Preview Classes Start */

/* Control Preview Classes End */

.jg-prvw-expand-bg {
  border-radius: 8px;
  /* background-color: #ECF1FF; */
  margin-bottom: 10px;
}

.jg-prvw-expand-bg>* {
  padding: 10px;
}

.jg-prvw-expand-bg:last-child {
  margin-bottom: 0;
}

.jg-pos-relative {
  position: relative;
}

.jg-btn {
  background-color: transparent;
  border: 0;
  outline: 0;
}

.jg-del-btn {
  padding: 5px;
}

.jg-expand-del-btn {
  position: absolute;
  top: 35px;
  right: 18px;
}

.jg-expand-add-btn {
  position: absolute;
  top: -30px;
  right: -6px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #ECF1FF;
  border-radius: 5px 5px 0 0;
}

.jg-option-add-btn {
  top: 1px;
  right: 11px;
}

.three-column .before-after-image-flex,
.four-column .before-after-image-flex {
  flex-direction: column;
}

.three-column .before-after-file-upload,
.four-column .before-after-file-upload {
  width: 100%;
}

.one-column .before-after-file-upload,
.two-column .before-after-file-upload {
  max-width: 50%;
}

.jg-expand-bg {
  /* background-color: #ECF1FF; */
  border-radius: 0.625rem;
  margin-bottom: 15px;
}

.jg-expand-bg:last-child {
  margin-bottom: 0px;
}

.jg-expand-header,
.jg-expand-description {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.jg-quiz-header {
  padding: 1rem 1.5rem;
  margin: 1rem 0;
}

.jg-expand-description {
  min-height: 80px;
}

.jg-expand-header-container {
  position: relative;
}

.jg-expand-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}

@media (max-width: 1200px) {

  .two-column .before-after-image-flex {
    flex-direction: column;
  }
}

.jg-pull-quote-border {
  border-left: 2px solid #E0E6F5;
}

.jg-ms-1 {
  margin-left: 16px;
}

.jg-mt-10px {
  margin-left: 10px;
}

.jg-gap-2 {
  gap: 0.5rem;
}

.px-12px {
  padding-left: 12px;
  padding-right: 12px;
}

.jg-align-items-end {
  align-items: flex-end;
}

.jg-text-center {
  text-align: center;
}

.jg-my-8px {
  margin-top: 8px;
  margin-bottom: 8px;
}

.jg-pb-4px {
  padding-bottom: 4px;
}

.jg-outline-none {
  outline: none;
}


.e-preview-blockquote {
  border-left: 2px solid #E0E6F5;
}

.e-prvw-breadcrumb-link {
  color: #6A75D3;
  /* text-decoration: underline; */
  word-break: keep-all;
}

.e-prvw-expand-bg {
  /* background-color: #ECF1FF; */
}

.jg-embed-container {
  /* position: relative;
    padding-top: 56.25%; */
}

.jg-iframe-element {
  /* position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border: none; */
}

.jg-cursor-pointer {
  cursor: pointer;
}

.jg-expand-b-border {
  border-bottom: 1px solid #ECF1FF;
}

.jg-ds-border {
  border: 1px solid #ECF1FF;
}

.jg-flex-column {
  flex-direction: column;
}

.jg-align-self-end {
  align-self: flex-end;
}

.jg-mx-20px {
  margin-left: 20px;
  margin-right: 20px;
}

.jg-pt-20px {
  padding-top: 20px;
}

.jg-align-items-center {
  align-items: center;
}

.jg-expand-padding-right {
  padding-right: 40px;
}

.jg-list-none {
  list-style: none;
}

.jg-ul {
  margin-bottom: 0;
  padding-left: 0;
}

.jg-px-10px {
  padding-left: 10px;
  padding-right: 10px;
}

.jg-py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.jg-pt-7px {
  padding-top: 7px;
}

/* begin: radio button */

.jg-radio[type="radio"]:checked,
.jg-radio[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.jg-radio[type="radio"]:checked+label,
.jg-radio[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.jg-radio[type="radio"]:checked+label:before,
.jg-radio[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 100%;
  background: #fff;
}

.jg-radio[type="radio"]:checked+label:after,
.jg-radio[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #000;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.jg-radio[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.jg-radio[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* end: radio button */

.jg-px-0px {
  padding-left: 0;
  padding-right: 0;
}

.jg-p-0 {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.jg-mb-10px {
  margin-bottom: 10px;
}

.jg-vote-btn {
  background-color: #14586d;
  border-radius: 6px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border: 0;
  outline: none;
  padding: 6px 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.jg-vote-btn:disabled {
  opacity: 0.8;
}

.jg-mr-3 {
  margin-right: 12px;
}

.jg-py-12px {
  padding-top: 12px;
  padding-bottom: 12px;
}

.jg-p-10px {
  padding: 10px;
}

.jg-mx-10px {
  margin: 0 10px;
}

.mobile .jg-author-flex {
  flex-direction: column;
  align-items: flex-start;
}

.jg-border-b-2px {
  border-bottom: 2px solid grey;
  margin-bottom: 15px;
}

.jg-pv-video {
  width: auto;
  max-width: 100%;
}

.jg-pb-10px {
  padding-bottom: 10px;
}

.jg-pb-10px {
  padding-bottom: 10px;
}

.jg-quiz-border-b {
  border-bottom: 2px solid #000;
}

.jg-px-5px {
  padding-left: 5px;
  padding-right: 5px;
}

.res-embed-wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.res-embed-wrapper .jg-iframe-element {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.jg-poll-border-container {
  border: 3px solid #9db6c5;
  padding-left: 10px;
  padding-right: 10px;
}

.jg-author-text {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.jg-drop-cap-pragraph::first-letter {
  font-size: 300%;
  float: left;
  /* vertical-align: text-top; */
  line-height: 1;
  padding-right: 5px;
  font-weight: 500;
}

.jg-text-bold {
  font-weight: bold;
}

.jg-top-40px {
  top: 40px;
}

.jg-h-550px {
  height: 550px;
}

.article-invalid {
  border: 2px solid red !important;
}

.article-img-invalid {
  border: 2px dashed red !important;
}