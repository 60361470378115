.drag-icon{
    color: var(--#{$prefix}draggable-icon-color);
}

.category-custom-border{
    border: 1px solid var(--#{$prefix}input-border);
}
.category-title{
    color: var(--#{$prefix}input-color);
}

// modal-action page 

.category-custom-border:last-child{
    margin-bottom:0 !important;
}