.border-light-purple {
    border: 2px solid var(--#{$prefix}table-pagination-border-color);
}

.border-input {
    border: 2px solid var(--#{$prefix}input-border);
}

.outline-none {
    outline: 0;
}