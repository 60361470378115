.text-control-btn {
  line-height: 1;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
  }
}

.text-control-bar {
  border: 2px solid #b8c1d8;
  border-bottom: 0;
  border-top-left-radius: 0.475rem;
  border-top-right-radius: 0.475rem;
  padding: 8px;
  flex-wrap: wrap;

  >* {
    flex-shrink: 0;
  }
}

.template-app-wrapper {
  margin-left: calc(70px + var(--jg-app-sidebar-gap-start, 0px) + var(--jg-app-sidebar-gap-end, 0px)) !important;
  margin-top: 0;
}

.template-app-wrapper {
  display: none;
}

.resolution-screen {
  height: 50%;
}

.tablet-resolution {
  display: none;
}

@media (min-width: 1024px) {
  .resolution-screen-wrapper {
    display: none !important;
  }

  .template-app-wrapper {
    display: block;
  }
}

.review-article {
  @media (max-width: 1024px) {
    .resolution-screen-wrapper {
      display: flex !important;
    }

    .template-app-wrapper {
      display: none;
    }
  }
}

@media (max-width: 992px) {
  .resolution-screen-wrapper {
    display: block;
  }
}

@media (max-width: 767px) {
  .dekstop-resolution {
    display: none;
  }
}

@media (min-width: 768px) {
  .tablet-resolution {
    display: none;
  }

  .resolution-screen {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .resolution-screen {
    width: 80%;
  }
}

@media (min-width: 480px) {
  .mobile-resolution {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 767px) {

  .tablet-resolution {
    display: block;
  }

}