.text-capitalize {
  text-transform: capitalize;
}

.break-word {
  word-break: break-word;
}

.fs-24px {
  font-size: 24px;
}

.fs-18px {
  font-size: 18px;
}

.text-grey {
  color: var(--#{$prefix}page-title);
}
