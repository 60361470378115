// All text responsive

.mobile {

  .global-txt-responsive,
  .global-txt-responsive * {
    font-size: 11px !important;
  }
}

.fatafat {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 0;
  }
}

.fatafat {

  .global-txt-responsive,
  .global-txt-responsive * {
    font-size: 13px !important;
  }
}

.tablet {

  .global-txt-responsive,
  .global-txt-responsive * {
    font-size: 12px !important;
  }
}

.desktop {

  .global-txt-responsive,
  .global-txt-responsive * {
    font-size: 16px;
  }
}

// Headline text

.mobile {

  .headline-txt-responsive,
  .headline-txt-responsive * {
    font-size: 14px !important;
  }
}

.fatafat {

  .headline-txt-responsive,
  .headline-txt-responsive * {
    font-size: 17px !important;
  }
}

.tablet {

  .headline-txt-responsive,
  .headline-txt-responsive * {
    font-size: 20px !important;
  }
}

.desktop {

  .headline-txt-responsive,
  .headline-txt-responsive * {
    font-size: 32px;
  }
}

// Author text

.mobile,
.fatafat {

  .author-txt-responsive,
  .author-txt-responsive * {
    font-size: 9px !important;
  }
}

.tablet {

  .author-txt-responsive,
  .author-txt-responsive * {
    font-size: 10px !important;
  }
}

.desktop {

  .author-txt-responsive,
  .author-txt-responsive * {
    font-size: 13px;
  }
}