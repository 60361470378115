/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

@import '../node_modules/codemirror/lib/codemirror';
@import '../node_modules/codemirror/theme/xq-light.css';
@import '../node_modules/codemirror/theme/material.css';
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/idea.css';
@import '~codemirror/addon/fold/foldgutter.css';
@import '~codemirror/addon/lint/lint.css';
