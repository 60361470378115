:root {
    // global variables
    --#{$prefix}e-toolbar-height: 50px;
    --#{$prefix}e-toolbar-preview-gap: 12.25px;
}

@media (min-width: 992px) {
    :root {
        --#{$prefix}app-header-height: 62px;
    }

    [data-kt-app-sidebar-minimize=on] {
        --#{$prefix}app-sidebar-width: 70px;
    }
}