// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// @media (min-width: 320px) {

//     .template-title,
//     .title-short-form {
//         font-size: 2rem;
//     }
// }

// // Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

    .template-title,
    .title-short-form {
        font-size: 2rem;
    }
}

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) {

//     .template-title,
//     .title-short-form {
//         font-size: 2rem;
//     }
// }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) {

//     .template-title,
//     .title-short-form {
//         font-size: 2rem;
//     }
// }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

    .template-title,
    .title-short-form {
        font-size: 2.3rem;
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {

    .template-title,
    .title-short-form {
        font-size: 2rem;
    }
}

.title-short-form {
    background-color: var(--#{$prefix}template-short-form-bg);
}

.title-short-form-defalut {
    background-color: var(--#{$prefix}light-blue);
}

// template-action style 

.stepper.stepper-pills .stepper-item.completed .stepper-icon {
    background-color:var(--#{$prefix}light-blue) !important;
}

.stepper.stepper-pills .stepper-item.completed .stepper-line {
    border-color:var(--#{$prefix}light-blue) !important;
}

.stepper.stepper-pills .stepper-item.current .stepper-icon {
    // border-color:var(--#{$prefix}light-blue) !important;
    border: 2px solid var(--#{$prefix}light-blue) !important;
}
