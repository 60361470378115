.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.hidden-scrollbar {

    // Firefox
    scrollbar-width: 0;

    // Webkit
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        height: 0;
        width: 0;
    }
}