.toolbar-ngb-dropdown {
    .toolbar-ngb-dropdown_text {
        color: var(--jg-gray-500);
        display: inline-block;
        height: 100%;
        padding-left: 8px;
        padding-right: 2px;
        position: relative;
        width: 100%;
        font-size: 12px;
        cursor: pointer;
    }
    .toolbar-ngb-dropdown_text:hover {
        color: var(--jg-primary);
    }
    .dropdown-menu {
        max-height: 260px;
        overflow: auto;
        padding: 0.5rem 0.7rem;
        min-width: auto;
    }
    .dropdown-item {
        outline: none;
        margin-bottom: 3px;
        padding: 5px 6px;
        border-radius: 4px;
        color: var(--jg-gray-600);
        font-size: 12px;
        transition: background-color 0.2s ease-in-out;
    }
    .dropdown-item:hover {
        background-color: #e2eaff;
        color: var(--jg-primary);
    }
    .dropdown-toggle::after {
        display: none;
    }
}
.toolbar-ngb-dropdown.show {
    .toolbar-ngb-dropdown_text {
        color: var(--jg-primary) !important;
    }
}
