.editor-container-wrapper {

    .cdk-drag-container {
        position: relative;
        border: var(--#{$prefix}editor-border-color) 1px dashed;
        width: 100%;
    }

    .label-container {
        position: relative;
        border: var(--#{$prefix}editor-border-color) 1px dashed;
        width: 100%;
    }

    .wrapper {
        display: flex;
        min-height: 100px;
        height: fit-content;
        padding: 4px;
        gap: 5px;

        .item {
            border-radius: 10px;
            width: 100%;
            min-height: 150px;
            height: fit-content;
        }
    }
}

.editor-control-block {
    border: 2px solid var(--#{$prefix}editor-border-color);
    min-height: 100px;
    padding: 4px;
}

.editor-dashed-layout-border {
    border: 1px dashed var(--#{$prefix}editor-border-color);
}

.editor-solid-layout-border {
    border: 1px dashed var(--#{$prefix}editor-border-color);
}

.editor-control-textarea {
    width: 100%;
    height: 100%;
    word-break: break-all;
    outline: 0;
    resize: none;
}

.draggable-node {
    // min-height: 40px;
    height: fit-content;
}

.editor-container-label {
    position: absolute;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 4px 10px;
    background-color:var(--#{$prefix}light-purple);
    top: -28px;
    left: -2px;
    cursor: pointer;

    @media (max-width: 1280px) {
        left: 50%;
        transform: translateX(-50%);
    }

    &.inner {
        cursor: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        top: -27px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0px 10px;
        width: fit-content;
        white-space: nowrap;
        height: 26px;

        @media (max-width: 1280px) {
            left: 0;
            transform: none;
        }

        &.prvw-design {
            height: 27px;
        }

        .delete-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            cursor: pointer;

            &:hover {
                background-color: var(--#{$prefix}danger-light);
            }
        }

        .move-btn {
            cursor: grab;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &.add-content-icon {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        bottom: -27px;
        left: 26px;
        transform: translateX(-50%);
        // padding: 0px 10px;
        width: fit-content;
        white-space: nowrap;
        height: 26px;
        top: auto;
        border-radius: 0 0 6px 6px;
    }

    &.right-side-btns {
        left: auto;
        right: -2px;
        background-color:var(--#{$prefix}bg-transparent);
        display: flex;
        gap: 8px;
        padding: 0;

        @media (max-width: 1280px) {
            right: -32px;
            transform: translateX(-50%);
        }

        .delete-btn,
        .move-btn {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 4px 8px;
        }

        .delete-btn {
            background-color: var(--#{$prefix}danger-light);
            cursor: pointer;
        }

        .move-btn {
            background-color:var(--#{$prefix}light-purple);
            cursor: grab;
        }
    }
}

// drag drop styling

.cdk-drag-animating {
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box {
    padding-top: 30px;
}

.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

// .cdk-drag-placeholder {
//     // border: var( --#{$prefix}editor-border-color) 1px dashed;
//     width: 100%;
// }

#preview .cdk-drop-list {
    min-height: 100px;
}

// .example-custom-placeholder {
//     background-color:var(--#{$prefix}custom-placeholder);
//     border: dotted 3px var(--#{$prefix}border-gray);
//     min-height: 80px;
//     transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
// }


.tag-disable {
    opacity: 30%;
    cursor: not-allowed;
    pointer-events: none;

}

.prv-line-break-hr {
    opacity: 1;
    margin: 0;
}

.toolbar-ng-select {
    .ng-select-container {
        padding: 0.2rem 0.5rem !important;
        width: 120px;
        border-radius: 6px !important;
    }

    .ng-dropdown-panel {
        padding: 0.6rem 0.65rem;
    }

    .ng-option {
        border-radius: 4px;
    }

    .ng-value-container .ng-input {
        padding-left: 0.5rem !important;
    }

    .ng-clear-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
    }

    .ng-arrow-wrapper {
        padding-right: 0;
    }

    .ng-value {
        line-height: 1;
    }
}

.drawer-wrapper {
    display: flex;

    @media (max-width: 1400px) {
        position: absolute;
        right: 0;
        height: 100%;

        &.closed {
            display: none;
        }
    }
}

.editor-drawer-shadow {
    box-shadow: 0px 12px 18px #DADEE8;
}

.jg-expand-title {
    .ql-editor {
        min-height: 50px;
    }
}

.jg-container-wrapper {
    border: var(--#{$prefix}editor-border-color) 1px dashed;
    width: 100%;
    padding: 8px;
    gap: 8px;
}

.preview-audio {
    border: 2px solid var(--#{$prefix}editor-border-color);
    width: 100%;
    padding: 1rem;
    min-height: 100px;

    img {
        height: 100px;
        width: 200px;
        border-radius: 10px;
    }

    .preview-video-caption {
        border: 2px solid var(--#{$prefix}editor-border-color);
        width: 100%;
        padding: 1rem;
        word-wrap: break-word;
        margin-top: 20px;
        min-height: 40px;
        display: flex;
        align-items: center;
    }

    .audio-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20px;

        .audio-title {
            margin-bottom: 0 !important;
            display: block;
            white-space: nowrap
        }

        span {
            white-space: nowrap;
        }

        h5 {
            white-space: nowrap;
        }
    }

    .audio-desc {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .audio-tag {
            background-color: var(--#{$prefix}border-gray);
            color: white;
            font-size: 10px;
            border-radius: 10px;
            display: block;
            text-align: center;
        }
    }
}

.fn-audio {
    width: 100%;

    img {
        height: 100px;
        width: 150px;
        border-radius: 10px;
    }

    .fn-audio-caption {
        border: 2px solid var(--#{$prefix}editor-border-color);
        width: 100%;
        padding: 1rem;
        word-wrap: break-word;
        margin-top: 20px;
        min-height: 40px;
        display: flex;
        align-items: center;
    }

    .audio-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20px;

        .audio-title {
            margin-bottom: 0 !important;
            display: block;
            white-space: nowrap
        }

        span {
            white-space: nowrap;
        }

        h5 {
            white-space: nowrap;
        }
    }

    .audio-desc {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .audio-tag {
            background-color: var(--#{$prefix}border-gray);
            color:var(--#{$prefix}white-color);
            font-size: 10px;
            border-radius: 10px;
            display: block;
            text-align: center;
        }
    }
}

.ql-font-mirza {
    font-family: "Mirza" !important;
}


.ql-font-playfair {
    font-family: 'Playfair Display', serif;
}

.ql-font-hind-madurai {
    font-family: 'Hind Madurai', sans-serif;
}

.ql-font-roboto {
    font-family: "Roboto" !important;
}

.ql-font-merriweather {
    font-family: "Merriweather,serif" !important;
}

.ql-font-inconsolota {
    font-family: "Inconsolata" !important;
}

.ql-font-arial {
    font-family: "Arial" !important;
}

.ql-font-serif {
    font-family: Georgia, Times New Roman, serif;
}

.ql-font-monospace {
    font-family: "Monaco", Courier New, monospace;
}

.ql-font-popins {
    font-family: "Poppins", sans-serif !important;
}

.acc-container {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--jg-light-purple);
    margin-bottom: 10px;
    border-radius: 4px;

}

.transition-abc {
    transition: .2s;
}

.acc-icon {
    transition: 0.3s transform ease-in-out
}

.audio-date {
    font-size: 12px;
    color: var(--#{$prefix}border-gray);
}

// .example-custom-placeholder {
//     background:var(--#{$prefix}custom-placeholder);
//     border: dotted 3px var(--#{$prefix}border-gray);
//     min-height: 150px;
//     transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
// }

.preview-inline-text {
    margin: 0 auto;
}

.object-contain {
    object-fit: contain;
}

// .editor-container-wrapper {

//     .cdk-drag-container {
//         position: relative;
//         border: #A5B3DB 1px dashed;
//         width: 100%;
//     }

//     .label-container {
//         position: relative;
//         border: #A5B3DB 1px dashed;
//         width: 100%;
//     }

//     .wrapper {
//         display: flex;
//         min-height: 100px;
//         height: fit-content;
//         padding: 4px;
//         gap: 5px;

//         .item {
//             border-radius: 10px;
//             width: 100%;
//             min-height: 150px;
//             height: fit-content;
//         }
//     }
// }

// .editor-control-block {
//     border: 2px solid #A5B3DB;
//     min-height: 100px;
//     padding: 4px;
// }

// .editor-dashed-layout-border {
//     border: 1px dashed #A5B3DB;
// }

// .editor-solid-layout-border {
//     border: 1px dashed #A5B3DB;
// }

// .editor-control-textarea {
//     width: 100%;
//     height: 100%;
//     word-break: break-all;
//     outline: 0;
//     resize: none;
// }

// .draggable-node {
//     // min-height: 40px;
//     height: fit-content;
// }

// .editor-container-label {
//     position: absolute;
//     border-top-left-radius: 10px;
//     border-top-right-radius: 10px;
//     padding: 4px 10px;
//     background-color:var(--#{$prefix}light-purple);
//     top: -30px;
//     left: -2px;
//     cursor: pointer;

//     &.inner {
//         cursor: auto;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         gap: 4px;
//         top: -27px;
//         left: 50%;
//         transform: translateX(-50%);
//         padding: 0px 10px;
//         width: fit-content;
//         white-space: nowrap;

//         &.prvw-design {
//             height: 27px;
//         }

//         .delete-btn {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             padding: 4px 7px;
//             cursor: pointer;

//             &:hover {
//                 background-color: var(--#{$prefix}danger-light);
//             }
//         }

//         .move-btn {
//             cursor: grab;
//         }
//     }

//     &.right-side-btns {
//         left: auto;
//         right: -2px;
//         background-color: transparent;
//         display: flex;
//         gap: 8px;
//         padding: 0;

//         .delete-btn,
//         .move-btn {
//             border-top-left-radius: 10px;
//             border-top-right-radius: 10px;
//             padding: 4px 8px;
//         }

//         .delete-btn {
//             background-color: var(--#{$prefix}danger-light);
//             cursor: pointer;
//         }

//         .move-btn {
//             background-color:var(--#{$prefix}light-purple);
//             cursor: grab;
//         }
//     }
// }

// drag drop styling

// .cdk-drag-animating {
//     transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
// }

// .example-box {
//     padding-top: 30px;
// }

// .cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
//     transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
// }

// .cdk-drag-placeholder {
//     // border: #A5B3DB 1px dashed;
//     width: 100%;
// }

#preview .cdk-drop-list {
    min-height: 100px;
}

.example-custom-placeholder {
    background-color: var(--jg-light-purple);
    border: 1px dashed var(--jg-editor-border-color);
    min-height: 70px;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}


.tag-disable {
    opacity: 30%;
    cursor: not-allowed;
    pointer-events: none;

}

.prv-line-break-hr {
    opacity: 1;
    margin: 0;
}

.toolbar-ng-select {
    .ng-select-container {
        padding: 0.2rem 0.5rem !important;
        width: 120px;
        border-radius: 6px !important;
    }

    .ng-dropdown-panel {
        padding: 0.6rem 0.65rem;
    }

    .ng-option {
        border-radius: 4px;
    }

    .ng-value-container .ng-input {
        padding-left: 0.5rem !important;
    }

    .ng-clear-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
    }

    .ng-arrow-wrapper {
        padding-right: 0;
    }

    .ng-value {
        line-height: 1;
    }
}

.drawer-wrapper {
    display: flex;

    @media (max-width: 1400px) {
        position: absolute;
        right: 0;
        height: 100%;

        &.closed {
            display: none;
        }
    }
}

.editor-drawer-shadow {
    box-shadow: 0px 12px 18px #DADEE8;
}

.jg-expand-title {
    .ql-editor {
        min-height: 50px;
    }
}

.jg-container-wrapper {
    border: #A5B3DB 1px dashed;
    width: 100%;
    padding: 8px;
    gap: 8px;
}

// .preview-audio {
//     border: 2px solid #A5B3DB;
//     width: 100%;
//     padding: 1rem;
//     min-height: 100px;

//     img {
//         height: 100px;
//         width: 200px;
//         border-radius: 10px;
//     }

//     .preview-video-caption {
//         border: 2px solid #A5B3DB;
//         width: 100%;
//         padding: 1rem;
//         word-wrap: break-word;
//         margin-top: 20px;
//         min-height: 40px;
//         display: flex;
//         align-items: center;
//     }

//     .audio-details {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         padding-left: 20px;

//         .audio-title {
//             margin-bottom: 0 !important;
//             font-size: 10px;
//             display: block;
//             white-space: nowrap
//         }

//         span {
//             white-space: nowrap;
//         }

//         h5 {
//             white-space: nowrap;
//         }
//     }

//     .audio-desc {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;

//         .audio-tag {
//             background-color: #999;
//             color: white;
//             font-size: 10px;
//             border-radius: 10px;
//             display: block;
//             text-align: center;
//         }
//     }
// }

// .fn-audio {
//     width: 100%;

//     img {
//         height: 100px;
//         width: 150px;
//         border-radius: 10px;
//     }

//     .fn-audio-caption {
//         border: 2px solid #A5B3DB;
//         width: 100%;
//         padding: 1rem;
//         word-wrap: break-word;
//         margin-top: 20px;
//         min-height: 40px;
//         display: flex;
//         align-items: center;
//     }

//     .audio-details {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         padding-left: 20px;

//         .audio-title {
//             margin-bottom: 0 !important;
//             font-size: 10px;
//             display: block;
//             white-space: nowrap
//         }

//         span {
//             white-space: nowrap;
//         }

//         h5 {
//             white-space: nowrap;
//         }
//     }

//     .audio-desc {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;

//         .audio-tag {
//             background-color: #999;
//             color: white;
//             font-size: 10px;
//             border-radius: 10px;
//             display: block;
//             text-align: center;
//         }
//     }
// }


.editor-container-wrapper {
    max-width: 100%;

    &.desktop {
        width: 1160px;
    }

    &.tablet {
        width: 540px;
    }

    &.mobile {
        width: 360px;
    }

    &.fatafat {
        width: 360px;
    }
}

.ql-align-center {
    text-align: center;
}

.ds-preview {
    border: 2px solid #A5B3DB;
    width: 100%;
    padding: 1rem;
    word-break: break-word;

    .ds-caption {
        border: 2px solid #A5B3DB;
        // width: 100%;
        padding: 1rem;
        margin-top: 20px;
        min-height: 18px;
    }

    p {
        margin: 0;
    }
}

.fn-preview {
    width: 100%;
    word-break: break-word;
    margin-bottom: 10px;

    .fn-caption {
        // width: 100%;
        padding: 1rem;
    }

    p {
        margin: 0;
    }
}

.ms-custom-3 {
    margin-left: 0.75rem !important;
}

.css-reset {
    padding: 0;
    margin: 0;
    all: revert;
    color: unset;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }
}

.desktop {


    .laptop-preview {
        display: block !important;
    }
}

.tablet {
    .device-view {
        flex-wrap: wrap;
        gap: 0;

        .one-column {
            width: 100% !important;
        }

        .two-column,
        .three-column,
        .four-column {
            width: 50% !important;
            padding: 5px !important;
        }
    }



    .tablet-preview {
        display: block !important;
    }

}

.mobile {
    .device-view {
        flex-direction: column;
        align-items: center;

        .one-column {
            width: 100% !important;
        }

        .two-column,
        .three-column,
        .four-column {
            width: 100% !important;
            padding: 5px !important;
        }
    }



    .mobile-preview {
        display: block !important;
    }
}

.fatafat {
    .device-view {
        flex-direction: column;
        align-items: center;

        .one-column {
            width: 100% !important;
        }

        .two-column,
        .three-column,
        .four-column {
            width: 100% !important;
            padding: 5px !important;
        }
    }


    .fatafat-preview {
        display: block !important;
    }
}

@media (max-width:1024px) {
    .fatafat {
        .fatafat-preview {
            display: none !important;
        }
    }
}

.mobile {
    .prv-video {
        width: 100% !important;
    }
}

.pv-quiz-option,
.ds-quiz-option {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.mobile {
    .wrapper {
        width: 100% !important;
    }
}

.ds-featured-summary,
.ds-featured-paragraph {
    min-height: 100px;
}

.ds-fn-embed-height {
    height: 350px;
}

.mobile {
    .ds-fn-embed-height {
        height: 180px;
    }
}

.jg-pv-media {
    max-height: 400px;
    object-fit: contain;
    background-color: rgb(243, 245, 255);
}

.math-laoder {
    border: 5px solid #f3f3f3;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.correct_answer {
    background-color: #b6d7a8 !important;
    border: 2px solid #7acc58;
}

.wrong_answer {
    background-color: #ea9999 !important;
    border: 2px solid #e45d5d;
}

.fEgGvl{
    height: auto !important;
    flex-direction: row !important;
}