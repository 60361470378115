.modal-header,
.modal-footer {
   background-color: var(--#{$prefix}light-purple);
}

.modal-close-btn {
   background-color: var(--#{$prefix}white-color) !important;
}

// stepper style 



.stepper.stepper-pills.stepper-column .stepper-line {
   margin-left: calc(30px / 2);
}

.modal-backdrop {
   height: 100%;
   width: 100%;
}