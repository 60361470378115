.px-15px {
  @media (max-width: 991.98px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  @media (min-width: 992px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.py-assign-btn {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

.bg-assign-btn {
  background-color: var(--#{$prefix}light-purple) !important;
  &:hover {
    background-color: var(--jg-primary) !important;
  }
}

.py-assign-badge {
  padding-top: 0.33rem !important;
  padding-bottom: 0.33rem !important;
}

.pb-1px {
  padding-bottom: 1px !important;
}
