@import "/node_modules/@ng-select/ng-select/themes/default.theme.css";

:root {
    --#{$prefix}ng-selected: var(--#{$prefix}primary-light);
}

.custom-ng-select {

    .ng-select .ng-select-container,
    .ng-dropdown-panel.ng-select-bottom {
        background-color: var(--#{$prefix}body-bg);
        border: 1px solid $input-border-color;
    }

    .ng-dropdown-panel {
        border-radius: $border-radius;
        // border-radius: var(--jg-dropdown-border-radius);
        box-shadow: $dropdown-box-shadow;
        border: 0 !important;
        margin-top: 0;
        padding: 0.75rem 0;
        background-color: var(--#{$prefix}body-bg);
    }

    .ng-select .ng-select-container {
        border-radius: $border-radius;
        // border-radius: var(--jg-dropdown-border-radius);
        height: auto;
        min-height: auto;
        padding: 0.75rem 1rem;
        font-size: 1.1rem;

        .ng-value-container {
            padding-left: 0;
        }
    }

    .ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
        border-bottom-right-radius: $border-radius;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: $border-radius;
        border-bottom-left-radius: 3px;
    }

    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
        top: unset;
    }

    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
        padding-left: 1rem;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        background-color: var(--#{$prefix}body-bg);
        color: var(--#{$prefix}gray-700);
    }

    // Hover & Focuse
    .ng-select .ng-select-container:hover {
        box-shadow: none;
    }

    .ng-select.ng-select-focused,
    .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
        box-shadow: none;
        border-color: $input-border-color;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
        background-color: var(--#{$prefix}ng-selected);
        color: var(--#{$prefix}primary);
        transition: color 0.2s ease, background-color 0.2s ease;

        .form-check {
            cursor: pointer;
        }
    }

    // Header
    .ng-dropdown-panel .ng-dropdown-header {
        border-color: $input-border-color;

        .select-header-btn {
            padding: 0.25rem 0.5rem !important;
        }
    }

    // Multiple select
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container,
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
        padding: 0;
    }

    // tag
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        margin: 0.2rem 0.4rem 0.2rem 0;
        background-color: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
        border-radius: 0.475rem;
        padding: 0.2rem 0.4rem;
        display: flex;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
        background-color: transparent;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
        order: 1;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
        order: 2;
        border: 0;
    }

    // Small
    &.ng-select-sm .ng-select .ng-select-container {
        min-height: calc(1.5em + 1.1rem + 2px);
        padding: 0.55rem 0.75rem;
        font-size: 0.925rem;
        border-radius: $border-radius;
        border-radius: 3px;
    }

    &.ng-select-sm .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
        padding-left: 0.75rem;
    }

    // Small
    &.ng-select-lg .ng-select .ng-select-container {
        min-height: calc(1.5em + 1.65rem + 2px);
        padding: 0.825rem 1.5rem;
        font-size: 1.15rem;
        border-radius: $border-radius;
        border-radius: 3px;
    }

    &.ng-select-lg .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
        padding-left: 1.5rem;
    }

    // Solid
    &.ng-select-solid .ng-select .ng-select-container {
        background-color: var(--#{$prefix}gray-100);
        border-color: var(--#{$prefix}gray-100);
        color: var(--#{$prefix}gray-700);
        transition: color 0.2s ease, background-color 0.2s ease;
    }

    &.ng-select-solid .ng-select.ng-select-focused,
    &.ng-select-solid .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
        background-color: var(--#{$prefix}gray-100);
        border-color: var(--#{$prefix}gray-100);
        color: var(--#{$prefix}gray-700);
        border-radius: $border-radius;
        border-radius: 3px;
    }

    &.ng-select-solid .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        background-color: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
    }

    &.ng-select-solid .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
        color: #efefef;
    }

    // Error UI
    .ng-select.is-invalid .ng-select-container {
        border-color: var(--#{$prefix}danger);
        background-color: var(--#{$prefix}danger-light);
    }

    .ng-select-multiple {
        .ng-select-container {
            &.ng-has-value {
                padding: 5px 10px;
            }
        }
    }
}

.ng-dropdown-panel {
    z-index: 1100 !important;
}