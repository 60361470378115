// .qlbt-col-tool {
//     height: 23px !important;
//     left: 16px !important;
//     top: 30.4531px !important;
// }
// 
// .qlbt-col-tool .qlbt-col-tool-cell {
//     height: 23px !important;
//     border: 0 !important;
//     background-color: transparent !important;
// }

.quill-better-table-wrapper {
    table td {
        border-color: #CDD6ED !important;
    }
}

.fn-table-style{
    .quill-better-table-wrapper{
        width: 100%;
        overflow-x: auto;
    } 
}
.ds-table-style{
    .quill-better-table-wrapper{
        width: 100%;
        overflow-x: auto;
    } 
}

.qlbt-col-tool {
    height: 16px !important;
    // left: 16px !important;
    z-index: 0 !important;
}

.qlbt-col-tool-cell {
    height: 14px !important;
    background-color:var(--#{$prefix}light-purple)!important;
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.qlbt-col-tool-cell::after {
    position: absolute;
    content: "";
    display: block;
    height: 8px;
    left: 1.5px;
    width: 1px;
    background-color: gray;
    margin: 2.5px 0;
}

.qlbt-col-tool-cell:before {
    position: absolute;
    content: "";
    display: block;
    height: 8px;
    right: 1.5px;
    width: 1px;
    background-color: gray;
    margin: 2.5px 0;
}