// .text-control-btn {
//     border: 0;
//     outline: 0;
//     background-color: transparent;
//     line-height: 1;
//     height: 24px;
//     width: 24px;
//     border-radius: 4px;
//     cursor: pointer;

//     &:hover {
//         background-color: #fff;
//     }
// }

// .text-control-bar {
//     border-top-left-radius: 6px;
//     border-top-right-radius: 6px;
//     padding: 5px 10px;
//     background-color:var(--#{$prefix}light-purple);
// }

// .ql-active {
//     background-color: var(--jg-primary-inverse);
// }

.ql-toolbar {
    padding: 4px;
    gap: 4px;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0;
}

.txt-edtr-border {
    border: 2px solid #b8c1d8 !important;
    border-top: 0 !important;
}