.btn {
    .svg-icon {
        transition: fill 0.15s ease-in-out;
    }
}

.btn-primary {
    background-color: var(--#{$prefix}btn-primary) !important
}

.btn.purple-active-btn {
    background-color: var(--#{$prefix}btn-primary) !important;

    .svg-icon {
        color: var(--#{$prefix}white) !important;
    }

    &:focus:not(.btn-active),
    &:hover:not(.btn-active) {
        background-color: var(--#{$prefix}btn-primary) !important;

        .svg-icon {
            color: var(--#{$prefix}white) !important;
        }
    }
}

.jg-danger-light{
    background-color:var(--#{$prefix}delet-btn-color) !important;
}