.light-ng-select {
  &.ng-select {
    .ng-select-container {
      border-radius: 3px;
      border: 2px solid var(--#{$prefix}input-border);
      height: 40px;

      .ng-value-container {
        padding-left: 12px;

        .ng-input {
          top: 7.5px;
        }
      }
    }
  }
}

.small-select-editor.is-invalid {
  .ng-select-container {
    border-color: var(--jg-danger) !important;
  }
}

.custom-ng-select {

  .small-select-editor.ng-select.ng-select-multiple,
  .small-select-editor.ng-select.ng-select-single {

    .ng-clear-wrapper {
      .ng-clear {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
      }
    }

    .ng-arrow-wrapper {
      width: 17px;
    }

    @media (max-width: 767px) {
      .ng-value-label {
        font-size: 0.75rem !important;
      }

      .ng-arrow-wrapper {

        .ng-arrow {
          border-width: 4px 4px 2px;
        }
      }
    }

    .ng-select-container {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      padding-left: 0.6rem;
      padding-right: 0.6rem;
      border-radius: 0.175rem;
      border: 2px solid var(--jg-input-border);
      cursor: pointer;

      .ng-value-container {
        .ng-placeholder {
          @media (max-width: 767px) {
            font-size: 0.75rem !important;
          }
        }
      }
    }

    .ng-arrow-wrapper {
      padding-right: 0;
    }

    .ng-value-container {
      align-items: center;
    }
  }

  .small-select-editor.ng-select.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          padding-left: 0.6rem;
        }
      }
    }
  }

  .small-select-editor {
    &.ng-select {
      &.ng-select-single {
        &:not(.full-container) {
          .ng-select-container {
            width: 80px;
          }
        }
      }
    }
  }
}

.custom-ng-select {
  .toolbar-ng-select {
    &.ng-select {
      .ng-arrow-wrapper {
        width: 18px;
        height: 18px;

        >.ng-arrow {
          display: none;
        }

        &::after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath fill='currentColor' d='m7 11 2 2 2-2H7zm0-4 2-2 2 2H7z' stroke='%23444' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A");
        }
      }

      .ng-select-container {
        width: 75px;
        font-size: 12px;
        color: var(--jg-gray-500);
        gap: 10px;
        background-color: transparent;
        border: 0;
      }

      .ng-dropdown-panel {
        .ng-dropdown-panel-items {
          .ng-option {
            margin-bottom: 3px;
            padding-left: 6px;
            padding-right: 6px;
            line-height: 1;
            font-size: 12px;
          }
        }
      }

    }

    &.ng-select {
      &.ng-select-opened {
        &.ng-select-bottom>.ng-select-container {
          background-color: transparent;
          color: var(--jg-primary);
        }

        .ng-arrow-wrapper {
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath fill='currentColor' d='m7 11 2 2 2-2H7zm0-4 2-2 2 2H7z' stroke='%2310174F' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
  }
}