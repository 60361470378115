

.stepper.stepper-pills .stepper-item.completed .stepper-line{
    border-color:var(--#{$prefix}primary) !important ;
}


.stepper.stepper-pills .stepper-item.current {
    .stepper-label .stepper-title {
       color: var(--#{$prefix}primary);
    }
 
    .stepper-icon {
       background-color: unset;
       border-color:var(--#{$prefix}primary) !important;

    }
 }
 
 .stepper.stepper-pills .stepper-item.completed {
    .stepper-label .stepper-title {
       color: var(--#{$prefix}default);
    }
 
    .stepper-icon {
       background-color: var(--#{$prefix}primary) !important;
 
       .stepper-check {
          color: var(--#{$prefix}white) !important;
       }
    }
 }

 .stepper-icon {
    background-color: unset !important;
    border: 1px solid var(--#{$prefix}light-purple) !important;
 }
 
 .stepper-number {
    color: var(--#{$prefix}primary) !important;
 }