.bg-light-purple {
  background-color: var(--#{$prefix}light-purple);
}

.bg-active-light-purple {
  &.active {
    background-color: var(--#{$prefix}light-purple) !important;
  }
}

.bg-tab-bg {
  background-color: var(--#{$prefix}tab-bg);
}

.app-header-light-separator-bg {
  background-color: var(--jg-app-header-light-separator-color);
}

.text-2d2d4a {
  color:var(--#{$prefix}input-color);
}

.bg-fafafc {
  background-color: var(--#{$prefix}stripped-color);
}

.bg-F5F7FD {
  background-color:var(--#{$prefix}bg-color);
}

.text-6374F1 {
  color:var(--#{$prefix}file-upload-text-color);
}

.bg-F3F5FF {
  background-color:var(--#{$prefix}file-upload-bg) !important;
}

.bg-transparent {
  background-color:var(--#{$prefix}bg-transparent);
}

.bg-ccc {
  background-color: #ccc;
}

.bg-eee {
  background-color: #eee;
}

.text-mute-A1A5B7 {
  color: #A1A5B7;
}

.pv-expand-style {
  border-top: 3px solid #ECF1FF;
  padding-top: 10px;
}

.pv-line-break-style {
  min-height: 0;
}

.text-D3DAFB {
  color: #D3DAFB;
}

.text-232428 {
  color: #232428;
}

.dark-success {
  color: var(--#{$prefix}green-color);
}

.dark-danger {
  color: var(--#{$prefix}badge-deactive-color);
}

.text-2785DD {
  color: #2785DD;
}

.modal-bg-tab {
  background-color: var(--#{$prefix}-modal-tab-bg);
}

.bg-bcecfb {
  background-color: #BCECFB;
}

.bg-static-range {
  background-color: var(--#{$prefix}static-range-bg);
}