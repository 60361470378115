.table-header-bg {
  background-color: var(--#{$prefix}light-purple);
}

.table-shadow {
  box-shadow: 0px 16px 16px #dadee8;
}

.page-link.active,
.active>.page-link {
  background-color: var(--#{$prefix}light-purple);
  color: var(--#{$prefix}input-color) !important;
}

.pagination {
  &.pagination-outline {
    .page-link {
      border: 1.5px solid var(--#{$prefix}table-pagination-border-color);
    }
  }
}

.p-datatable {
  .p-datatable-thead {
    >tr {
      >th {
        &:first-child {
          padding-left: 40px !important;
        }
      }
    }
  }
}

.p-treetable {
  .p-treetable-thead {
    >tr {
      >th {
        &:first-child {
          padding-left: 40px !important;
        }
      }
    }
  }
}

.p-datatable {
  .p-datatable-thead {
    >tr {
      >th {
        &:last-child {
          padding-right: 40px !important;
        }
      }
    }
  }
}

.p-datatable {
  .p-datatable-tbody {
    td:first-child {
      padding-left: 40px !important;
    }

    td:last-child {
      padding-right: 40px !important;
    }
  }
}

.p-treetable {
  .p-treetable-tbody {
    td:first-child {
      padding-left: 40px !important;
    }

    td:last-child {
      padding-right: 40px !important;
    }
  }
}

.p-datatable {
  .p-datatable-tbody {
    >tr {
      >td {
        padding: 0.9rem 1rem;
      }
    }
  }
}

.p-treetable {
  .p-treetable-tbody {
    >tr {
      >td {
        padding: 0.9rem 1rem;
      }
    }
  }
}

.p-paginator {
  .p-dropdown {
    border: 1.5px solid var(--#{$prefix}table-pagination-border-color);
  }
}

.p-datatable {
  .p-datatable-thead {
    >tr {
      >th {
        white-space: nowrap;
        padding: 1rem;
      }
    }
  }
}

.p-treetable {
  .p-treetable-thead {
    >tr {
      >th {
        white-space: nowrap;
        padding: 1rem;
      }
    }
  }
}

.calender-icon {
  left: 20px;
  top: 47% !important;
}

.date-close-icon {
  right: -15px !important;
}

.edit-assignee-list,
.share-template-list,
.media-list-custom-style,
.topic-list,
.feed-list,
.my-task-list {
  &.app-table {
    .custom-ng-select {
      .ng-select {
        .ng-select-container {
          padding: 0.48rem 1rem;
          border-color: var(--jg-gray-300) !important;

          @media (max-width: 767px) {
            // padding-top: 0.22rem !important;
            // padding-bottom: 0.22rem !important;
            padding: 0.22rem 0.7rem;
            // font-size: 0.75rem;
          }
        }
      }
    }

    .media-table-select {
      border: 0 !important;
      border-color: var(--jg-gray-300) !important;
    }
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre !important;
}

.p-datatable {
  .p-datatable-tbody {
    >tr {
      >td {
        color: var(--#{$prefix}table-column-color);
      }
    }
  }
}

.p-treetable {
  .p-treetable-tbody {
    >tr {
      >td {
        color: var(--#{$prefix}table-column-color);
      }
    }
  }
}


.paginator-text-table {
  color: var(--#{$prefix}table-column-color);
}

.page-item .page-link {
  min-width: 3rem;
  min-height: 2.7rem;
}

.page-item:hover:not(.active):not(.offset):not(.disabled) .page-link {
  color: var(--#{$prefix}pagination-disabled-color);
}

.role-acion-table tr:nth-child(even) {
  background-color: var(--#{$prefix}stripped-color);
}

.hidden-checkbox {
  visibility: hidden;
  pointer-events: none !important;
}

.filter-modal-body {
  table {
    tbody {
      tr {
        td {
          padding-bottom: 3px;
        }
      }
    }
  }
}

.p-custom-checkbox {
  display: block !important;
  flex: unset !important;
  padding: 0.9rem 1rem;
}