p-paginator.p-element.grid-pagination {
    .p-paginator.p-component {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .page-item:hover {
        .page-link {
            border-color: var(--#{$prefix}table-pagination-border-color) !important;
        }
    }
}