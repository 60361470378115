.jg-header {

    // styles
    background-color: var(--#{$prefix}app-body-bg-color);
    box-shadow: var(--#{$prefix}app-header-base-box-shadow);
    border-bottom: var(--#{$prefix}app-header-base-border-bottom);

    // inner classes

}

.app-header {
    box-shadow: none;
    border: none;
}