.auth-form-title {
  color: var(--#{$prefix}auth-form-title);
}

.auth-bg {
  @media (min-width:992px) {
    background-image: url('/assets/media/auth/login-bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

.auth-mogile-bg {
  @media (max-width:992px) {
    background-image: url('/assets/media/auth/login-bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

.auth-form-box {
  @media (max-width:992px) {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    margin-top: -40px;
  }
}

.active-underline:hover {
  text-decoration: underline !important;
}

.auth-mogile-bg {
  @media (min-height:1024px) {
    height: auto !important;
  }
}