.gap-e-toolbar {
    gap: var(--#{$prefix}e-toolbar-preview-gap);
}

.h-e-toolbar {
    height: var(--#{$prefix}e-toolbar-height);
}

.h-e-preview {
    height: calc(100% - (var(--#{$prefix}e-toolbar-height) + 10px));
}

.control-container-border {
    border: 1px solid #b8c1d8;
}

.control-container-padding {
    padding: 4px;
    padding-top: 0;
}

