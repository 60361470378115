.arrow-list>li::marker {
    content: '>';
    font-size: 1.2em;
}

.check-list>li::marker {
    content: '✔️';
    font-size: 1.2em;
}

.triangle-list>li::marker {
    content: '🔼';
    font-size: 1.2em;
}

.star-list>li::marker {
    content: 'ABC';
    font-size: 1.2em;
}