#splash-screen {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color:var( --#{$prefix}splash-color);
  }
  
  #splash-screen img {
    margin-left: calc(100vw - 100%);
    margin-bottom: 10px;
  }
  
  #splash-screen.hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  .splash-spinner {
    animation: rotate 2s linear infinite;
    margin-left: calc(100vw - 100%);
    width: 50px;
    height: 50px;
  }
  
  .splash-spinner .path {
    stroke: #5d78ff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
  
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
  
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  