// light mode variables
:root,
[data-bs-theme="light"] {
    --#{$prefix}auth-form-title: #4A5B6D;
    --#{$prefix}sidebarmenu-heading: #dabde3;
    --#{$prefix}menu-title: currentcolor;
    --#{$prefix}input-border: #E0E6F5;
    --#{$prefix}input-color: #2D2D4A;
    --#{$prefix}page-title: #8D97AD;
    --#{$prefix}btn-primary: #76182f;
    --#{$prefix}delet-btn-bg: #FB7A7A;
    --#{$prefix}delet-btn-color: #FFF0F0;
    --#{$prefix}light-purple: #ecb8bb30;
    --#{$prefix}table-pagination-border-color: #E0E6F5;
    --#{$prefix}table-column-color: #8D97AD;
    --#{$prefix}white-color: #FFFF;
    --#{$prefix}badge-active-bg: #A3F5D9;
    --#{$prefix}badge-active-color: #2A7A5F;
    --#{$prefix}badge-deactive-bg: #FF96A2;
    --#{$prefix}badge-deactive-color: #7A2A33;
    --#{$prefix}sidebar-svg-icon: #ABB2E7;
    --#{$prefix}template-short-form-bg: #D7DAF0;
    --#{$prefix}light-blue: #2E3888;
    --#{$prefix}dark-blue: #10174F;
    --#{$prefix}draggable-icon-color: #CBD5EF;
    --#{$prefix}custom-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --#{$prefix}tab-bg: #ecb8bb30;
    --#{$prefix}file-upload-bg: #F3F5FF;
    --#{$prefix}file-upload-text-color: #6374F1;
    --#{$prefix}black-color: black;
    --#{$prefix}green-color: green;
    --#{$prefix}stripped-color: #FAFAFC;
    --#{$prefix}bg-color: #F5F7FD;
    --#{$prefix}splash-color: #f2f3f8;
    --#{$prefix}number-text-color: #f2f2f2;
    --#{$prefix}editor-border-color: #A5B3DB;
    --#{$prefix}bg-transparent: #a5b3db00;
    --#{$prefix}bg-gray: #717171;
    --#{$prefix}bg-light-green: #21b384;
    --#{$prefix}border-gray: #999;
    --#{$prefix}custom-placeholder: #ccc;
    --#{$prefix}modal-tab-bg: #fafbff;
    --#{$prefix}static-range-bg: #dcdff1;
}

// dark mode variables
:root,
[data-bs-theme="dark"] {
    --#{$prefix}auth-form-title: #4A5B6D;
    --#{$prefix}sidebarmenu-heading: #dabde3;
    --#{$prefix}menu-title: currentcolor;
    --#{$prefix}input-border: #E0E6F5;
    --#{$prefix}input-color: #2D2D4A;
    --#{$prefix}page-title: #8D97AD;
    --#{$prefix}btn-primary: #76182f;
    --#{$prefix}delet-btn-bg: #FB7A7A;
    --#{$prefix}delet-btn-color: #FFF0F0;
    --#{$prefix}light-purple: #ecb8bb30;
    --#{$prefix}table-pagination-border-color: #E0E6F5;
    --#{$prefix}table-column-color: #8D97AD;
    --#{$prefix}white-color: #FFFF;
    --#{$prefix}badge-active-bg: #A3F5D9;
    --#{$prefix}badge-active-color: #2A7A5F;
    --#{$prefix}badge-deactive-bg: #FF96A2;
    --#{$prefix}badge-deactive-color: #7A2A33;
    --#{$prefix}sidebar-svg-icon: #ABB2E7;
    --#{$prefix}template-short-form-bg: #D7DAF0;
    --#{$prefix}light-blue: #2E3888;
    --#{$prefix}dark-blue: #10174F;
    --#{$prefix}draggable-icon-color: #CBD5EF;
    --#{$prefix}custom-box-shadow: 0px 0px 50px 0px rgba(97, 86, 110, 0.15);
    --#{$prefix}tab-bg: #ecb8bb30;
    --#{$prefix}file-upload-bg: #F3F5FF;
    --#{$prefix}file-upload-text-color: #6374F1;
    --#{$prefix}black-color: black;
    --#{$prefix}green-color: green;
    --#{$prefix}stripped-color: #FAFAFC;
    --#{$prefix}bg-color: #F5F7FD;
    --#{$prefix}splash-color: #f2f3f8;
    --#{$prefix}number-text-color: #f2f2f2;
    --#{$prefix}editor-border-color: #A5B3DB;
    --#{$prefix}bg-transparent: #a5b3db00;
    --#{$prefix}bg-gray: #717171;
    --#{$prefix}bg-light-green: #21b384;
    --#{$prefix}border-gray: #999;
    --#{$prefix}custom-placeholder: #ccc;
    --#{$prefix}modal-tab-bg: #fafbff;
    --#{$prefix}static-range-bg: #dcdff1;
}