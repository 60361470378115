.common-custom-accordion {

    .accordion-button {
        padding: 0.5rem 0.75rem;
    }

    .accordion-body {
        padding: 0.75rem;
    }

    .accordion-button:not(.collapsed) {
        box-shadow: none;
    }

    .accordion-button {
        background-color: var(--#{$prefix}white);
        color: var(--#{$prefix}text-primary);
        font-weight: 500;
        font-size: 1rem;
    }
}

